import React from 'react'

const Image = props => {
    const { media, image, className, title, height, width } = props
    return (
        <img
            className={className}
            src={media ? media[image.id] : ''}
            alt={title ? title : image.name.split('.').slice(0, -1).join('.')}
            title={title ? title : image.name.split('.').slice(0, -1).join('.')}
            height={height}
            width={width}
        />
    )
}

export default Image
