import React from "react"
import Icon from "./Icon"
import Image from "./Image"

const AbstractMedia = props => {
    let { media, abstractMedia, className, title } = props
    return (
        <>
            {
                abstractMedia && abstractMedia.icon && (
                    <Icon icon={abstractMedia.icon} className={className} />
                )
            }
            {
                abstractMedia && abstractMedia.image && (
                    <Image image={abstractMedia.image} media={media} className={className} title={title} />
                )
            }
        </>
    )
}

export default AbstractMedia
