import React from "react"
import AbstractMedia from "../../components/abstractMedia/AbstractMedia";
import Title from "../../components/Title"

const TitleBlock = (props) => {
    const { id, title, balise, decoration, backgroundImage, opacity, internal } = props.data

    let titleJSX = null;
    if (title && title.length !== 0) {
        if (!backgroundImage && decoration) {
            titleJSX = (
                <div className="roof-wrapper">
                    <div className="roof">
                        <AbstractMedia media={props.media} abstractMedia={decoration} className="img-top" />
                        <Title balise={balise}>{title}</Title>
                    </div>
                </div>
            )
        } else {
            titleJSX = (
                <div className={`${title.length > 100 ? "text-left" : "text-center"} ${backgroundImage ? "bg-title" : "title"}`}>
                    <Title balise={balise} className={title.length > 100 ? "small" : null}>{title}</Title>
                </div>
            )
        }
    }

    return (
        <div id={`title-${id}`} className="title-block">
            {backgroundImage ? (
                <div className="background-image">
                    {internal ? (
                        <img src={backgroundImage} alt={(title && title !== "") && title} title={(title && title !== "") && title} opacity={opacity} className={"w-100-large"} />
                    ) : (
                        <AbstractMedia media={props.media} abstractMedia={backgroundImage} title={(title && title !== "") && title} opacity={opacity} className={"w-100-large"} />
                    )}
                    <div className="container">
                        {titleJSX}
                    </div>
                </div>
            ) : (
                    <div className="container">
                        {titleJSX}
                    </div>
                )}
        </div>
    )
}

export default TitleBlock;