import React from 'react'

function Title({ children, className, balise }) {
    let title = null
    switch (balise) {
        case "H1":
            title = (<h1 className={className}>{children}</h1>)
            break
        case "H2":
            title = (<h2 className={className}>{children}</h2>)
            break
        case "H3":
            title = (<h3 className={className}>{children}</h3>)
            break
        case "H4":
            title = (<h4 className={className}>{children}</h4>)
            break
        case "H5":
            title = (<h5 className={className}>{children}</h5>)
            break
        default:
            title = (<h6 className={className}>{children}</h6>)
            break
    }
    return title
}

export default Title
